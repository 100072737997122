@font-face {
  font-family: "openSans-bold";
  src: url("../../assets/fonts/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "openSans-BoldItalic";
  src: url("../../assets/fonts/OpenSans-BoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "openSans-CondLight";
  src: url("../../assets/fonts/OpenSans-CondLight.ttf") format("opentype");
}

@font-face {
  font-family: "openSans-CondLightItalic";
  src: url("../../assets/fonts/OpenSans-CondLightItalic.ttf") format("opentype");
}

@font-face {
  font-family: "openSans-ExtraBold";
  src: url("../../assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "openSans-ExtraBoldItalic";
  src: url("../../assets/fonts/OpenSans-ExtraBoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "openSans-Italic";
  src: url("../../assets/fonts/OpenSans-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "openSans-Light";
  src: url("../../assets/fonts/OpenSans-Light.ttf") format("opentype");
}

@font-face {
  font-family: "openSans-LightItalic";
  src: url("../../assets/fonts/OpenSans-LightItalic.ttf") format("opentype");
}

@font-face {
  font-family: "openSans-Regular";
  src: url("../../assets/fonts/OpenSans-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "openSans-SemiBold";
  src: url("../../assets/fonts/OpenSans-SemiBold.ttf") format("opentype");
}
