//Font - OpenSans
$openSans-bold: openSans-bold, sans-serif;
$openSans-BoldItalic: openSans-BoldItalic, sans-serif;
$openSans-CondLight: openSans-CondLight, sans-serif;
$openSans-CondLightItalic: openSans-CondLightItalic, sans-serif;
$openSans-ExtraBold: openSans-ExtraBold, san-serif;
$openSans-ExtraBoldItalic: openSans-ExtraBoldItalic, sans-serif;
$openSans-Italic: openSans-Italic, sans-serif;
$openSans-Light: openSans-Light, sans-serif;
$openSans-LightItalic: openSans-LightItalic, sans-serif;
$openSans-Regular: openSans-Regular, sans-serif;
$openSans-SemiBold: openSans-SemiBold, sans-serif;

//fonts in css
.openSans-bold {
  font-family: $openSans-bold;
}
.openSans-BoldItalic {
  font-family: $openSans-BoldItalic;
}
.openSans-CondLight {
  font-family: $openSans-CondLight;
}
.openSans-CondLightItalic {
  font-family: $openSans-CondLightItalic;
}
.openSans-ExtraBold {
  font-family: $openSans-ExtraBold;
}
.openSans-ExtraBoldItalic {
  font-family: $openSans-ExtraBoldItalic;
}
.openSans-Italic {
  font-family: $openSans-Italic;
}
.openSans-Light {
  font-family: $openSans-Light;
}
.openSans-LightItalic {
  font-family: $openSans-LightItalic;
}
.openSans-Regular {
  font-family: $openSans-Regular;
}
.openSans-SemiBold {
  font-family: $openSans-SemiBold;
}
