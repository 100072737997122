.audio-player-container {
    width: 100%;
  }
  
  .audio-player {
    width: 100%;
    height: 32.5px;
  }
  
  .audio-player::-webkit-media-controls-play-button {
    background-color: #ffffff;
    border-radius: 5px;
    height: 24px;
    outline: 2px solid #00838c;
    margin-right: 10px;
  }
  
  .audio-player::-webkit-media-controls-panel {
    background-color: #ffffff;
  }
  
  .audio-player::-webkit-media-controls-mute-button { display: none !important; } 
  
  .audio-player::-webkit-media-controls-current-time-display {
    display: none;
  }
  
  .audio-player::-webkit-media-controls-time-remaining-display{
    display: none;
  }
  
  .audio-player::-webkit-progress-value {
    background-color: #00838c !important; 
    display: none;
  }
  
  .audio-player::-webkit-media-controls-volume-slider {
    display: none !important;
    -webkit-appearance: none;
  }
  .audio-player::-webkit-media-controls-mute-button {
    display: none !important;
    -webkit-appearance: none;
  }
  
  