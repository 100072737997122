.video-js .vjs-big-play-button {
    width: 134px;
    height: 42px;
    border-radius: 4px;
    background-color: #00838c;
    line-height: 42px;
    border: none;
    .vjs-icon-placeholder:before {
      content: "Watch Tutorial";
      font-family: openSans-SemiBold, sans-serif !important;
      font-size: 14px;
    }
  }
  .vjs-big-play-centered .vjs-big-play-button {
    margin-left: -2em;
  }
  .vjs-tech {
    outline: none;
  }
  .video-js {
    border-radius: 6px;
    overflow: hidden;
  }
  .vjs-poster {
    background-image: none;
    background-color: black;
  }
  
  .video-js:hover .vjs-big-play-button {
    background-color: #00838c;
  }
  