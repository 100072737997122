@import "../abstract/variables";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-size: 14px;
  font-family: $openSans-Regular;
  color: #1e2d4f;
}
